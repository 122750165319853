/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.grid-galerij').packery({
          itemSelector: '.grid-galerij-item'
        });

        $('.grid-collage').packery({
          itemSelector: '.grid-collage-item'
        }); 
        
        function scrollToAnchor(hash) {
            var target = $(hash),

                wpadminbar   = ( $("#wpadminbar").length ) ? $("#wpadminbar").height() : 0;
                headerHeight = $("#header").height() + wpadminbar; // Get fixed header height
  
            target = target.length ? target : $('[name=' + hash.slice(1) +']');
        
            if (target.length)
            {
                $('html,body').animate({
                    scrollTop: target.offset().top - headerHeight
                }, 400);
                return false;
            }
        }
        
        if(window.location.hash) {
            scrollToAnchor(window.location.hash);
        }
        
        $("a[href*=\\#]:not([href=\\#])").click(function()
        {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
                || location.hostname == this.hostname)
            {
        
                scrollToAnchor(this.hash);
            }
        });

        $('.slider-hero').slick({ 
          autoplay: true,
          pauseOnHover: false,
          arrows: false,
          speed: 400,
          autoplaySpeed: 8000,
        });

        $('.slider-project .slider').slick({
          infinite: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          speed: 400,
          appendArrows: '.slider-project-nav',
          nextArrow: '<button class="slick-next slick-arrow btn btn-outline-primary btn-icon" aria-label="Next" type="button" aria-disabled="true"><i class="fas fa-arrow-right"></i></button>',
          prevArrow: '<button class="slick-prev slick-arrow btn btn-outline-primary btn-icon" aria-label="Previous" type="button" aria-disabled="false" style=""><i class="fas fa-arrow-left"></i></button>',
          responsive: [
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            }
          ]
        }); 

        $('.slider-post .slider').slick({
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          speed: 400,
          nextArrow: '<button class="slick-next slick-arrow btn btn-primary" aria-label="Next" type="button" aria-disabled="true"><i class="fas fa-chevron-right"></i></button>',
          prevArrow: '<button class="slick-prev slick-arrow btn btn-primary" aria-label="Previous" type="button" aria-disabled="false" style=""><i class="fas fa-chevron-left"></i></button>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            }
          ]
        });

        // header change on scroll
        $(window).scroll(function(){
          var sticky = $('#header'),
              scroll = $(window).scrollTop();
        
          if (scroll >= 100){
             sticky.addClass('fixed');
          }else{
            sticky.removeClass('fixed');
          }
        });

        // get size of project intro
        $('.consealer-btn').hide();

        console.log( $( '.project-intro' ).height() );

        if( $( '.project-intro' ).height() > 180 ){
            $( '.project-intro' ).addClass( 'overflow-consealed' );
            $('.consealer-btn').show();
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.consealer-btn').on( 'click', function(){
            
          $('.overflow-consealed').addClass( 'show_full_text' );
          
          $( this ).toggle();
          
        });	 

        $(".menu-toggle").on( 'click', function() {
          $('.hamburger', this).toggleClass('is-active');
          $(".nav-primary-overlay").toggleClass("active");
          $('body').toggleClass('noscroll');
        });

        $(".nav-primary-close").on( 'click', function() {
          $('.menu-toggle .hamburger').toggleClass('is-active');
          $(".nav-primary-overlay").toggleClass("active");
          $('body').toggleClass('noscroll');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'project': {
      init: function() {
        // JavaScript to be fired on the project page		
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
